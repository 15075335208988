@use 'styles/events-button' as *;

.Modal {
    text-shadow: none;
    margin-top: var(--spacing-medium);
    margin-bottom: var(--spacing-medium);
    padding: 0;
    height: max-content;
    max-width: 80vw;
    overscroll-behavior: contain;
    &::backdrop {
        background: hsla(0 0% 0% / 0.7);
        backdrop-filter: grayscale(1) blur(32px);
    }
    header:not(:empty) {
        position: sticky;
        z-index: var(--layer-menu);
        top: 0;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: var(--spacing-small-half);
        background: var(--colour-background-accent-primary);
        filter: var(--filter-shadow-mid);
        --colour-link: var(--colour-text-secondary);
        --colour-link-active: var(--colour-text-accent-primary);
        [data-site='events'] & {
            @media (max-width: 500px) {
                flex-direction: column;
                gap: var(--spacing-small);
            }
        }
        h1 {
            display: block;
            color: var(--colour-text-secondary);
            border: none;
            padding: 0 !important;
            margin: 0 var(--spacing-small) 0 0 !important;
            flex-grow: 1;
        }
    }
}

.ModalBody {
    display: grid;
    grid-template-areas: 
        "image text"
        "logo text";
    grid-template-columns: min-content 1fr;
    grid-template-rows: min-content 1fr;
    gap: var(--spacing-small);
    padding: var(--spacing-small);
}

.Image {
    position: relative;
    width: 200px;
    height: 200px;
    aspect-ratio: 1 / 1;
    object-fit: cover;
    border-radius: var(--spacing-small);
    > img {
        border-radius: var(--spacing-small);
    }
}

.SpeakerImage {
    grid-area: image;
}
.CompanyLogo {
    grid-area: logo;
    max-height: 150px;
}
.SpeakerText {
    grid-area: text;
    display: flex;
    gap: 0;
    flex-direction: column;
    flex-grow: 1;
    > h3 {
        margin-bottom: 0;
        padding-bottom: 0;
        color: var(--colour-text-primary);
    }
    > p {
        margin-top: var(--spacing-small-half);
    }
    > a {
        @include events-button;
        margin-top: var(--spacing-small-half);
    }
}